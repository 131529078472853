import React from "react";
import OutletWithErrorBoundary from "./OutletWithErrorBoundary";
import { useMobileViewState } from "../../../providers/MobileViewStateProvider";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import { useFireworkState } from "../../../providers/FireworkProvider";
import FireworkComponent from "../../fireworks/FireworkComponent";

const MainContentWrapper = () => {
  const { mobileView } = useMobileViewState();
  const { init } = useFireworkState();

  return (
    <main className={`${!mobileView ? "MainContent" : "MainContent-hidden"}`}>
      <Header />
      <Nav />
      <section className="MainContent__content">
        {!init && <OutletWithErrorBoundary />}
        {init && <FireworkComponent />}
      </section>
    </main>
  );
};

export default MainContentWrapper;
