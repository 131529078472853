import { COMMON } from "../constants/COMMON";
import { EntityModel } from "./EntityModel";
import { FirebaseDBService } from "../services/FirebaseDBService";
import { COLLECTIONS } from "../constants/COLLECTIONS";
import BrowserDBService from "../services/BrowserDBService";
import { WordModel } from "./WordModel";
import { GROUP_TYPES } from "../constants/GROUP_TYPES";

export class GroupModel extends EntityModel {
  static collection = COLLECTIONS.GROUPS;

  constructor(group) {
    super(group);
    this.name = group.name;
    this.firebaseId = group.firebaseId;
    this.pic = group.pic || COMMON.NO_PIC;
    this.description = group.description || "";
    this.wordCounter = group.wordCounter || 0;
    this.type = group.type;
    this.knownWords = group.knownWords || 0;
    this.learning = group.learning || false;
  }

  validateCustom(group) {
    if (!group.name) {
      throw new Error("no name");
    }
    if (!group.firebaseId) {
      throw new Error("no firebaseId");
    }
    if (!group.type) {
      throw new Error("no type");
    }
  }

  static async create(modelData) {
    const duplicate = await FirebaseDBService.getDocumentsWhere(
      GroupModel.collection,
      "name",
      modelData.name,
    );
    if (duplicate.length) {
      throw new Error("this group already exists!");
    }
    const group = await FirebaseDBService.createDocument(
      modelData,
      GroupModel.collection,
      (data) => new GroupModel(data),
    );
    await BrowserDBService.createDocument(group, GroupModel.collection);
    return group;
  }

  async update() {
    this.validate(this);
    await FirebaseDBService.saveDocumentById(this, GroupModel.collection);
    await BrowserDBService.saveDocumentById(this, GroupModel.collection);
  }

  static async getById(groupId) {
    const group = await BrowserDBService.getDocumentById(
      groupId,
      GroupModel.collection,
    );

    if (group) {
      return group;
    }

    const firebaseGroup = await FirebaseDBService.getDocumentById(
      groupId,
      GroupModel.collection,
    );
    await BrowserDBService.saveDocumentById(
      firebaseGroup,
      GroupModel.collection,
    );
    return firebaseGroup;
  }

  static async deleteEntity(id) {
    const words = await FirebaseDBService.getDocumentsWhere(
      WordModel.collection,
      "id",
      id,
    );
    if (words.length) {
      throw new Error("this group has words, remove them first!");
    }
    await FirebaseDBService.removeDocument(id, GroupModel.collection);
    await BrowserDBService.removeDocument(id, GroupModel.collection);
  }

  static async getAll() {
    let groups = await BrowserDBService.getAll(GroupModel.collection);

    if (groups.length) {
      return groups.sort((a, b) => a.name.localeCompare(b.name));
    }

    groups = await FirebaseDBService.getAll(GroupModel.collection);
    await BrowserDBService.saveAll(groups, GroupModel.collection);
    return groups.sort((a, b) => a.name.localeCompare(b.name));
  }

  static async downloadALLGroupsToLocalDB() {
    const groups = await FirebaseDBService.getAll(GroupModel.collection);
    await BrowserDBService.saveAll(groups, GroupModel.collection);
  }

  static createRawNew() {
    return { name: "", type: "", pic: COMMON.NO_PIC };
  }

  static async wordOnAdd(word) {
    //save in DB
    const group = await FirebaseDBService.getDocumentWhere(
      GroupModel.collection,
      "id",
      word.groupId,
    );
    group.wordCounter += 1;
    const newModel = new GroupModel(group);
    await FirebaseDBService.saveDocumentById(newModel, GroupModel.collection);

    //save in local DB, knowWords could be different
    const localGroup = await BrowserDBService.getDocumentById(
      word.groupId,
      GroupModel.collection,
    );
    localGroup.wordCounter += 1;
    await BrowserDBService.saveDocumentById(localGroup, GroupModel.collection);
    return localGroup;
  }

  static async onKnowWordSwitch(word) {
    const group = await BrowserDBService.getDocumentById(
      word.groupId,
      GroupModel.collection,
    );
    if (word.know) {
      group.knownWords++;
    } else {
      if (group.knownWords > 0) {
        group.knownWords--;
      }
    }

    await BrowserDBService.saveDocumentById(group, GroupModel.collection);
    return group;
  }

  static async wordOnDelete(word) {
    // work with DB
    const group = await FirebaseDBService.getDocumentWhere(
      GroupModel.collection,
      "id",
      word.groupId,
    );
    group.wordCounter -= 1;
    const newModel = new GroupModel(group);
    await FirebaseDBService.saveDocumentById(newModel, GroupModel.collection);

    //save in local DB, knowWords could be different
    const localGroup = await BrowserDBService.getDocumentById(
      word.groupId,
      GroupModel.collection,
    );
    localGroup.wordCounter -= 1;
    if (word.know) {
      localGroup.knownWords -= 1;
    }
    await BrowserDBService.saveDocumentById(localGroup, GroupModel.collection);
    return localGroup;
  }

  static async wordOnEdit(word, originalWord) {
    const futureLocalGroup = await BrowserDBService.getDocumentById(
      word.groupId,
      GroupModel.collection,
    );
    const groupChanged = word.groupId !== originalWord.groupId;

    if (!groupChanged) {
      return { newGroup: futureLocalGroup };
    }

    // Work with DB
    const futureGroupInDB = await FirebaseDBService.getDocumentWhere(
      GroupModel.collection,
      "id",
      word.groupId,
    );
    const previousGroupInDB = await FirebaseDBService.getDocumentWhere(
      GroupModel.collection,
      "id",
      originalWord.groupId,
    );
    previousGroupInDB.wordCounter -= 1;
    futureGroupInDB.wordCounter += 1;
    const newModel = new GroupModel(futureGroupInDB);
    const newOldModel = new GroupModel(previousGroupInDB);
    await Promise.all([
      FirebaseDBService.saveDocumentById(newModel, GroupModel.collection),
      FirebaseDBService.saveDocumentById(newOldModel, GroupModel.collection),
    ]);

    //work in local DB, knowWords could be different
    const previousLocalGroup = await BrowserDBService.getDocumentById(
      originalWord.groupId,
      GroupModel.collection,
    );
    futureLocalGroup.wordCounter += 1;
    previousLocalGroup.wordCounter -= 1;

    if (word.know) {
      futureLocalGroup.knownWords += 1;
      previousLocalGroup.knownWords -= 1;
    }
    await Promise.all([
      BrowserDBService.saveDocumentById(
        futureLocalGroup,
        GroupModel.collection,
      ),
      BrowserDBService.saveDocumentById(
        previousLocalGroup,
        GroupModel.collection,
      ),
    ]);
    return { oldGroup: previousLocalGroup, newGroup: futureLocalGroup };
  }

  static async syncWordsData(words, groupId) {
    const firebaseGroup = await FirebaseDBService.getDocumentById(
      groupId,
      GroupModel.collection,
    );

    if (!firebaseGroup) {
      throw new Error("no group!");
    }

    const newModel = new GroupModel(firebaseGroup);
    newModel.wordCounter = words.length;
    newModel.knownWords = 0;

    await Promise.all([
      BrowserDBService.saveDocumentById(newModel, GroupModel.collection),
      FirebaseDBService.saveDocumentById(newModel, GroupModel.collection),
    ]);

    return newModel;
  }

  static async massResetKnow(groupId) {
    const group = await BrowserDBService.getDocumentById(
      groupId,
      GroupModel.collection,
    );
    const newGroup = new GroupModel(group);
    newGroup.knownWords = 0;
    await BrowserDBService.saveDocumentById(newGroup, GroupModel.collection);
    return newGroup;
  }

  static getEmptyLearningGroups() {
    return Object.values(GROUP_TYPES).reduce((acc, value) => {
      acc[value] = "";
      return acc;
    }, {});
  }
}
