import { LogService } from "./LogService";

export class SpeakService {
  static synth = window.speechSynthesis;
  static voice;

  static speak(text) {
    try {
      if (!text) {
        return;
      }
      if (!SpeakService.voice) {
        const voices = SpeakService.synth.getVoices();
        let manVoice = voices.find(
          ({ name }) => name === "Google UK English Male",
        );

        if (!manVoice || !manVoice.name) {
          manVoice = voices.find(({ name }) => name.includes("English"));
        }
        SpeakService.voice = manVoice ?? voices[1];
      }
      const message = new SpeechSynthesisUtterance();
      message.lang = "en-US";
      message.text = text;
      message.pith = 1;
      message.rate = 0.9;
      message.voice = SpeakService.voice;

      SpeakService.synth.speak(message);
    } catch (e) {
      LogService.log("speak text error", e);
    }
  }
}
