import { GroupModel } from "../models/GroupModel";
import { WordModel } from "../models/WordModel";
import { UserModel } from "../models/UserModel";
import { PlanModel } from "../models/PlanModel";

class BrowserDBService {
  static dbName = "vocabulary";
  static db = null;

  getDB() {
    if (BrowserDBService.db) {
      return BrowserDBService.db;
    }

    const openRequest = indexedDB.open(BrowserDBService.dbName, 1);

    return new Promise((resolve, reject) => {
      openRequest.onerror = function () {
        reject(openRequest.error);
      };

      // triggers if the client had no database
      openRequest.onupgradeneeded = function (e) {
        try {
          const db = e.target.result;
          db.createObjectStore(GroupModel.collection, { keyPath: "id" });
          db.createObjectStore(UserModel.collection, { keyPath: "id" });
          db.createObjectStore(PlanModel.collection, {
            keyPath: "id",
          });
          const words = db.createObjectStore(WordModel.collection, {
            keyPath: "id",
          });

          words.createIndex("groupId", "groupId", { unique: false });
        } catch (e) {
          reject(e);
        }
      };

      openRequest.onsuccess = function () {
        const db = openRequest.result;
        db.onversionchange = function () {
          db.close();
          alert("Database is outdated, please reload the page.");
        };
        BrowserDBService.db = db;
        resolve(db);
      };
    });
  }

  async getAll(collectionName) {
    const db = await this.getDB();
    return new Promise((resolve, reject) => {
      const request = db
        .transaction(collectionName)
        .objectStore(collectionName)
        .getAll();

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (err) => {
        reject(err);
      };
    });
  }

  async createDocument(doc, collectionName) {
    const db = await this.getDB();
    return new Promise((resolve, reject) => {
      if (collectionName == null || doc == null) {
        reject(new Error("Incorrect save doc data"));
      }

      const request = db
        .transaction([collectionName], "readwrite")
        .objectStore(collectionName)
        .put(doc);
      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (err) => {
        reject(err);
      };
    });
  }

  async saveDocumentById(doc, collectionName) {
    // this is because we have PUT in createDocument
    return this.createDocument(doc, collectionName);
  }

  async removeDocument(id, collectionName) {
    const db = await this.getDB();
    return new Promise((resolve, reject) => {
      if (collectionName == null || id == null) {
        reject(new Error("Incorrect remove doc data"));
      }
      const request = db
        .transaction(collectionName, "readwrite")
        .objectStore(collectionName)
        .delete(id);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (err) => {
        reject(err);
      };
    });
  }

  async saveAll(docs, collectionName) {
    const db = await this.getDB();
    if (collectionName == null || !Array.isArray(docs)) {
      throw new Error("Incorrect saveAll data");
    }

    return new Promise((resolve, reject) => {
      const transaction = db.transaction(collectionName, "readwrite");

      transaction.oncomplete = function () {
        resolve();
      };

      transaction.onerror = function (event) {
        reject(event);
      };

      const objectStore = transaction.objectStore(collectionName);

      for (const doc of docs) {
        const request = objectStore.put(doc);
        request.onerror = (err) => {
          reject(err);
        };
      }
    });
  }

  async getDocumentById(id, collectionName) {
    const db = await this.getDB();
    return new Promise((resolve, reject) => {
      if (collectionName == null || id == null) {
        reject(new Error("Incorrect params"));
      }
      const request = db
        .transaction(collectionName, "readwrite")
        .objectStore(collectionName)
        .get(id);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (err) => {
        reject(err);
      };
    });
  }

  async getAllById(id, collectionName, indexBy) {
    const db = await this.getDB();

    return new Promise((resolve, reject) => {
      if (collectionName == null || id == null) {
        reject(new Error("Incorrect params"));
      }

      const transaction = db.transaction([collectionName], "readonly");
      const objectStore = transaction.objectStore(collectionName);
      const index = objectStore.index(indexBy);
      const request = index.getAll(id);

      request.onsuccess = () => {
        resolve(request.result);
      };

      request.onerror = (err) => {
        reject(err);
      };
    });
  }

  async searchDocumentsWhere(collectionName, prop, value) {
    const db = await this.getDB();

    return new Promise((resolve, reject) => {
      if (collectionName == null || prop == null || value == null) {
        reject(new Error("Incorrect params"));
      }

      const request = db
        .transaction(collectionName)
        .objectStore(collectionName)
        .getAll();

      request.onsuccess = () => {
        const res = request.result;
        const search = [];
        res.forEach((item) => {
          if (item[prop].toLowerCase().includes(value.toLowerCase())) {
            search.push(item);
          }
        });

        resolve(search.sort((a, b) => a[prop].localeCompare(b[prop])));
      };

      request.onerror = (err) => {
        reject(err);
      };
    });
  }
}

const instance = new BrowserDBService();
export default instance;
