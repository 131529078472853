import React from "react";
import Particles from "react-particles";
import {loadFireworksPreset} from "tsparticles-preset-fireworks";

class FireworkComponent extends React.PureComponent {
  // this customizes the component tsParticles installation
  async customInit(engine) {
    // this adds the preset to tsParticles, you can safely use the
    await loadFireworksPreset(engine);
  }

  render() {
    const options = {
      preset: "fireworks",
    };

    return <div style={{position: "relative"}}>
      <div style={{
        position: "absolute",
        color: 'white',
        zIndex: 100,
        left: 0,
        marginLeft: -25,
        right: 0,
        top: 100,
        fontSize: 30
      }}>
        😍🤩You are awesome!!!😍🤩
      </div>
      <Particles options={options} init={this.customInit}/>
    </div>
  }
}

export default FireworkComponent;
