export const GROUP_TYPES = {
  SENTENCES: "sentences",
  EXPRESSIONS: "expressions",
  VERBS: "verbs",
  THEME: "byTheme",
  PHRASAL_VERBS: "phrasalVerbs",
  ADJECTIVES: "adjectives",
  SUPPORT: "support",
  PREPOSITIONS: "prepositions",
  ADDITIONAL: "additional",
  IELTS: "ielts",
}