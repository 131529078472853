import React from "react";
import StatisticBlock from "./StatisticBlock/StatisticBlock";
import PlanBlock from "./PlanBlock/PlanBlock";
import { withErrorBoundary } from "../hocs/withErrorBoundary";

const DailyPlanState = ({ user, dayState }) => {
  return (
    <div className="flex-content">
      <StatisticBlock dayState={dayState} />
      <PlanBlock plan={user.plan} />
    </div>
  );
};

export default withErrorBoundary(DailyPlanState);
