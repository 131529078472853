import AttachmentIcon from "@mui/icons-material/Attachment";
import React from "react";
import IconButton from "@mui/material/IconButton";

const AttachmentIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <AttachmentIcon/>
    </IconButton>
  );
};

export default AttachmentIconComponent;
