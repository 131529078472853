import React from "react";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.scss";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDebouncedCallback } from "use-debounce";
import { WordModel } from "../../models/WordModel";
import { LogService } from "../../services/LogService";
import { useShowCommonPopup } from "../../providers/CommonPopupProvider";
import CloseIconComponent from "../library-based-components/icons/CloseIconComponent";
import { validEmail } from "../../helpers/validator.helper";
import { TranslationService } from "../../services/TranslationService";
import WordSearchRow from "./WordSearchRow/WordSearchRow";
import WordSearchCreateWord from "./WordSearchNoResult/WordSearchCreateWord";

const SearchWord = ({ className }) => {
  const [value, setValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [hints, setHints] = React.useState([]);
  const showError = useShowCommonPopup();

  const debounced = useDebouncedCallback(async (value) => {
    try {
      if (!value || value.length < 2) {
        setHints([]);
        return;
      }

      setLoading(true);

      let words;
      const transformedValue = value.replace(/\//g, "").trim();
      if (TranslationService.isItEnglish(value)) {
        words = await WordModel.searchWordByWordLocalDB(transformedValue);
      } else {
        words =
          await WordModel.searchWordByTranslationLocalDB(transformedValue);
      }
      const currentWords = words.splice(0, 50);
      setHints(currentWords);
      setLoading(false);
    } catch (e) {
      LogService.log("search Words error", e, showError);
      setLoading(false);
    }
  }, 1000);

  const reset = () => {
    setValue("");
    setHints([]);
  };

  return (
    <ClickAwayListener onClickAway={reset}>
      <div className="SearchWord">
        <TextField
          fullWidth
          className={`${className ? className : ""}`}
          value={value}
          onChange={(e) => {
            const value = e.target.value;
            // By some reason autoComplete off doesn't work
            if (validEmail(value)) {
              return;
            }
            debounced(value);
            setValue(value);
          }}
          variant="standard"
          label="Search word in local db"
          InputProps={{
            endAdornment: (
              <>
                {value && !loading ? (
                  <CloseIconComponent size="small" onClick={reset} />
                ) : null}
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
              </>
            ),
          }}
        />
        {!loading && value.length >= 3 && (
          <div className="SearchWord__hints custom-scroll">
            <ListItem style={{ height: 20 }}>
              <ListItemText primary={<WordSearchCreateWord word={value} />} />
            </ListItem>
            {hints.length > 0 &&
              hints.map((word) => (
                <ListItem key={word.id}>
                  <ListItemText
                    primary={<WordSearchRow word={word} reset={reset} />}
                  />
                </ListItem>
              ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default SearchWord;
