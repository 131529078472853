import React, { useCallback, useEffect, useState } from "react";
import { LogService } from "../services/LogService";
import { useShowCommonPopup } from "./CommonPopupProvider";
import { useAuth } from "./AuthProvider";
import { PlanModel } from "../models/PlanModel";
import { clone, showCongrats } from "../helpers/util.helper";
import { useSnack } from "./SnackProvider";
import ParticlesComponent from "../components/fireworks/ParticlesComponent";
import { useFireworkState } from "./FireworkProvider";
import { useLocation, useNavigate } from "react-router-dom";

const PlanContext = React.createContext(null);

export const PlanProvider = ({ children }) => {
  const showError = useShowCommonPopup();
  const user = useAuth().user;
  const [dayState, setDayState] = React.useState(null);
  const [counter, setCounter] = React.useState(0);
  const [particles, setParticles] = useState(false);
  const showSnack = useSnack();
  const { showFirework } = useFireworkState();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchTodayPlan = useCallback(
    async (userId) => {
      try {
        const dayState = await PlanModel.getTodayPlan(userId);
        setDayState(dayState);
        const counter = dayState.done.reduce((acc, current) => {
          acc = acc + Number(current.value);
          return acc;
        }, 0);
        setCounter(counter);
      } catch (e) {
        LogService.log("plan get data error", e, showError);
      }
    },
    [showError],
  );

  const increaseCounter = useCallback(
    (groupType) => {
      const id = PlanModel.getTodayPlanId();
      if (id !== dayState.id) {
        return fetchTodayPlan(user.id)
      } else {
        setCounter((oldCounter) => {
          oldCounter += 1;
          return oldCounter;
        });
      }

      const newState = clone(dayState);
      newState.done = newState.done.map((item) => {
        if (item.groupType === groupType) {
          item.value = (+item.value + 1).toString();
          return item;
        } else {
          return item;
        }
      });
      setDayState(newState);

      showCongrats({
        dayState: newState,
        showFirework,
        user,
        groupType,
        showSnack,
        showParticles: () => {
          setParticles(true);
          setTimeout(() => setParticles(false), 10000);
        },
        pathname: location.pathname,
        navigate,
      });
    },
    [
      dayState,
      showSnack,
      setParticles,
      user,
      showFirework,
      navigate,
      location.pathname,
      fetchTodayPlan
    ],
  );

  useEffect(() => {
    fetchTodayPlan(user.id);
  }, [fetchTodayPlan, user.id]);

  return (
    <PlanContext.Provider
      value={{
        dayState,
        wordsCounter: counter,
        increaseCounter,
        setDayState,
      }}
    >
      {particles && <ParticlesComponent />}
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => {
  const contextValue = React.useContext(PlanContext);
  if (!contextValue) {
    throw new Error("Tried to use context from outside the provider");
  }

  return contextValue;
};
