import React from "react";
import Tooltip from "@mui/material/Tooltip";

const WordStatusIcon = ({ word, onClick }) => {
  const style = {
    fontSize: 20,
  };
  if (word.know) {
    return (
      <Tooltip title={`I know this word`} placement="top">
        <span
          className="clickable"
          role="img"
          onClick={onClick}
          aria-label="I know"
          style={style}
        >
          😊
        </span>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={`I don't know this word`} placement="top">
        <span
          className="clickable"
          role="img"
          onClick={onClick}
          aria-label="do not know"
          style={style}
        >
          😰
        </span>
      </Tooltip>
    );
  }
};

export default WordStatusIcon;
