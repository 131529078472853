import React from "react";
import "./style.scss";
import RequireAuth from "../../../pages/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { withErrorBoundary } from "../../../hocs/withErrorBoundary";
import { ConfirmPopupProvider } from "../../../providers/ConfirmPopupProvider";
import { GroupsProvider } from "../../../providers/GroupsProvider";
import { GroupEditPopupProvider } from "../../../providers/GroupEditPopupProvider";
import { WordEditPopupProvider } from "../../../providers/WordEditPopupProvider";
import { WordsProvider } from "../../../providers/WordsProvider";
import { PlanProvider } from "../../../providers/PlanProvider";
import MainContentWrapper from "./MainContentWrapper";
import { MobileViewStateProvider } from "../../../providers/MobileViewStateProvider";
import { FireworkProvider } from "../../../providers/FireworkProvider";

const queryClient = new QueryClient();
const MainContent = () => {
  return (
    <RequireAuth>
      <ConfirmPopupProvider>
        <GroupsProvider>
          <WordsProvider>
            <GroupEditPopupProvider>
              <WordEditPopupProvider>
                <FireworkProvider>
                  <PlanProvider>
                    <QueryClientProvider client={queryClient}>
                      <MobileViewStateProvider>
                        <MainContentWrapper />
                      </MobileViewStateProvider>
                    </QueryClientProvider>
                  </PlanProvider>
                </FireworkProvider>
              </WordEditPopupProvider>
            </GroupEditPopupProvider>
          </WordsProvider>
        </GroupsProvider>
      </ConfirmPopupProvider>
    </RequireAuth>
  );
};

export default withErrorBoundary(MainContent);
