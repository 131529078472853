import LoopIcon from "@mui/icons-material/Loop";
import React from "react";
import { IconButton } from "@mui/material";

const LoopIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <LoopIcon />
    </IconButton>
  );
};

export default LoopIconComponent;
