import React from "react";
import {IconButton} from "@mui/material";
import PsychologyIcon from '@mui/icons-material/Psychology';

const PsychologyIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <PsychologyIcon/>
    </IconButton>
  );
};

export default PsychologyIconComponent;
