import React from "react";
import SheetIconComponent from "./library-based-components/icons/SheetIconComponent";
import { useShowCommonPopup } from "../providers/CommonPopupProvider";
import DailyPlanState from "./DailyPlanState";

const PlanIcon = ({ user, dayState }) => {
  const showDailyStatisticPopup = useShowCommonPopup();
  return (
    <SheetIconComponent
      onClick={() =>
        showDailyStatisticPopup({
          title: "Your Daily Statistic",
          content: <DailyPlanState user={user} dayState={dayState} />,
        })
      }
    />
  );
};

export default PlanIcon;
