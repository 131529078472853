export class BrowserStorageService {
  static setData(prop, data) {
    try {
      const item = JSON.stringify(data);
      localStorage.setItem(prop, item);
    } catch (e) {
      console.log("localStorage setData error", e);
    }
  }

  static getData(prop) {
    try {
      const item = localStorage.getItem(prop);
      return JSON.parse(item);
    } catch (e) {
      console.log("localStorage getData error", e);
    }
  }

  static removeData(prop) {
    localStorage.removeItem(prop);
  }
}
