import ColorLensIcon from "@mui/icons-material/ColorLens";
import React from "react";
import IconButton from "@mui/material/IconButton";

const ColorPaletteIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <ColorLensIcon/>
    </IconButton>
  );
};

export default ColorPaletteIconComponent;
