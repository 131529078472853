import React from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import IconButton from "@mui/material/IconButton";
import { SpeakService } from "../services/SpeakService";

const SpeakIconComponent = (props) => {
  const style = { ...props.style, marginRight: 16 };
  return (
    <IconButton
      {...props}
      style={style}
      onClick={() => SpeakService.speak(props.message)}
      size="large"
    >
      <VolumeUpIcon />
    </IconButton>
  );
};

export default SpeakIconComponent;
