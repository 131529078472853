import React from "react";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TimerIcon from "@mui/icons-material/Timer";
import { usePlan } from "../providers/PlanProvider";

const WordsCounterIcon = () => {
  const { wordsCounter } = usePlan();

  return (
    <Badge
      badgeContent={wordsCounter}
      color="secondary"
      max={9999}
      showZero
      overlap="circular"
    >
      <Tooltip title="Words counter">
        <IconButton size="large">
          <TimerIcon />
        </IconButton>
      </Tooltip>
    </Badge>
  );
};

export default WordsCounterIcon;
