import { EntityModel } from "./EntityModel";
import { COLLECTIONS } from "../constants/COLLECTIONS";
import { FirebaseDBService } from "../services/FirebaseDBService";
import { GROUP_TYPES } from "../constants/GROUP_TYPES";
import { UserModel } from "./UserModel";
import { clone } from "../helpers/util.helper";

export class PlanModel extends EntityModel {
  static collection = COLLECTIONS.PLANS;

  constructor(plan) {
    super(plan);
    this.done = plan.done;
    this.plan = plan.plan;
    this.notes = plan.notes;
  }

  validateCustom(plan) {
    if (!plan.plan.length) {
      throw new Error("plan.plan is empty");
    }
    if (!plan.done.length) {
      throw new Error("plan.done is empty");
    }
    if (
      plan.done.forEach((item) => {
        if (!item.groupType) {
          throw new Error("plan. no groupType");
        }
        if (!Number.isInteger(+item.value)) {
          throw new Error("plan.value is not a number");
        }

        if (item.value < 0) {
          throw new Error("plan. value has incorrect number");
        }
      })
    );
    if (
      plan.plan.forEach((item) => {
        if (!item.groupType) {
          throw new Error("plan. no groupType");
        }
        if (!Number.isInteger(+item.value)) {
          throw new Error("plan.value is not a number");
        }

        if (item.value < 0) {
          throw new Error("plan. value has incorrect number");
        }
      })
    );
    if (typeof plan.notes !== "string") {
      throw new Error("plan.data.notes are incorrect");
    }
  }

  static getEmptyData() {
    const groupsArr = Object.values(GROUP_TYPES);
    return {
      notes: "",
      groupsPlan: groupsArr.map((groupType) => {
        return {
          groupType,
          value: "0",
        };
      }),
    };
  }

  update() {
    this.validate(this);
    return FirebaseDBService.saveDocumentById(this, PlanModel.collection);
  }

  increaseGroupCounter(groupType) {
    this.done = this.done.map((item) => {
      if (item.groupType === groupType) {
        item.value = (+item.value + 1).toString();
        return item;
      }
      return item;
    });
  }

  static async deleteEntity(id) {
    return FirebaseDBService.removeDocument(id, PlanModel.collection);
  }

  static getTodayPlanId() {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const date = new Date();
    return date.toLocaleDateString("en-US", options);
  }

  static async getTodayPlan(userId) {
    if (!userId) {
      throw new Error("getTodayPlan no userId!");
    }
    const id = PlanModel.getTodayPlanId();
    let plan = await FirebaseDBService.getDocumentById(
      id,
      PlanModel.collection,
    );
    if (!plan) {
      const { plan: userPlan } = await UserModel.getById(userId);
      plan = new PlanModel({
        id,
        plan: clone(userPlan.groupsPlan),
        done: clone(userPlan.groupsPlan).map((item) => {
          item.value = "0";
          return item;
        }),
        notes: userPlan.notes,
      });
      await FirebaseDBService.saveDocumentById(plan, PlanModel.collection);
    }

    return plan;
  }

  static getAll() {
    return FirebaseDBService.getWithLimit(PlanModel.collection);
  }

  static async increaseCounter(userId, groupType) {
    const planData = await PlanModel.getTodayPlan(userId);
    const model = new PlanModel(planData);
    model.increaseGroupCounter(groupType);
    await model.update();
  }
}
