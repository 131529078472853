import { USER_ROLES } from "../constants/USER_ROLES";
import { COMMON } from "../constants/COMMON";
import { EntityModel } from "./EntityModel";
import { FirebaseDBService } from "../services/FirebaseDBService";
import { COLLECTIONS } from "../constants/COLLECTIONS";
import { PlanModel } from "./PlanModel";
import { GroupModel } from "./GroupModel";
import { clone } from "../helpers/util.helper";
import BrowserDBService from "../services/BrowserDBService";

export class UserModel extends EntityModel {
  static collection = COLLECTIONS.USERS;

  constructor(user) {
    super(user);
    this.name = user.name || "My name";
    this.firebaseId = user.firebaseId;
    this.pic = user.pic || COMMON.NO_PIC;
    this.role = user.role || USER_ROLES.USER;
    this.plan = user.plan || PlanModel.getEmptyData();
    this.learningGroups =
      user.learningGroups || GroupModel.getEmptyLearningGroups();
  }

  validateCustom(user) {
    if (!user.firebaseId) {
      throw new Error("no firebaseId");
    }
    if (!user.role) {
      throw new Error("no role for this user");
    }
    if (!user.name) {
      throw new Error("no name for this user");
    }

    if (!user.pic) {
      throw new Error("no pic for this user");
    }
  }

  static async getById(id) {
    try {
      return FirebaseDBService.getDocumentById(id, UserModel.collection);
    } catch (e) {
      if (e.message.includes("Quota exceeded")) {
        return BrowserDBService.getDocumentById(id, UserModel.collection);
      } else {
        throw e;
      }
    }
  }

  static async resetPass(email) {
    return FirebaseDBService.sendPasswordResetEmail(email);
  }

  /**
   * This will login user automatically>}
   */
  static async createByEmailAndPassword({ email, password }) {
    const firebaseUser =
      await FirebaseDBService.createUserByEmailAndPassword(email);
    const user = new UserModel({
      firebaseId: firebaseUser.user.uid,
      id: firebaseUser.user.uid,
    });
    await user.update();
  }

  async update() {
    this.validate(this);
    await BrowserDBService.saveDocumentById(this, UserModel.collection);
    return FirebaseDBService.saveDocumentById(this, UserModel.collection);
  }

  static async deleteEntity(id) {
    return FirebaseDBService.removeDocument(id, UserModel.collection);
  }

  static async switchLearning(user, group) {
    const newUser = clone(user);
    let value = newUser.learningGroups[group.type];
    if (value === group.id) {
      newUser.learningGroups[group.type] = "";
    } else {
      newUser.learningGroups[group.type] = group.id;
    }

    const newModel = new UserModel(newUser);
    setTimeout(async () => {
      await newModel.update();
    }, 100);
    return newModel;
  }
}
