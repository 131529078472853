import React from "react";
import "./style.scss";
import AvatarComponent from "../../library-based-components/AvatarComponent";
import { useAuth } from "../../../providers/AuthProvider";
import OffIconComponent from "../../library-based-components/icons/OffIconComponent";
import { canEditGroup } from "../../../helpers/security.helper";
import AddIconComponent from "../../library-based-components/icons/AddIconComponent";
import { useShowGroupEditPopup } from "../../../providers/GroupEditPopupProvider";
import { GroupModel } from "../../../models/GroupModel";
import { COMMON } from "../../../constants/COMMON";
import TooltippedAddButton from "../../library-based-components/TooltippedAddButton";
import { useShowWordEditPopup } from "../../../providers/WordEditPopupProvider";
import { WordModel } from "../../../models/WordModel";
import { useLocation } from "react-router-dom";
import SearchWord from "../../SearchWord/SearchWord";
import SecurityService from "../../../services/SecurityService";
import DownLoadAllWordsButton from "../../DownLoadAllWordsButton";
import { getGroupIdFromURL, isItGroupPage } from "../../../helpers/util.helper";
import PlanIcon from "../../PlanIcon";
import { usePlan } from "../../../providers/PlanProvider";
import WordsCounterIcon from "../../WordsCounterIcon";
import { useMobileViewState } from "../../../providers/MobileViewStateProvider";
import MenuClosedIconComponent from "../../library-based-components/icons/MenuClosedIconComponent";
import MenuIconComponent from "../../library-based-components/icons/MenuIconComponent";

const Header = () => {
  const auth = useAuth();
  const showEditGroup = useShowGroupEditPopup();
  const { showEditWord } = useShowWordEditPopup();
  const location = useLocation();
  const { dayState } = usePlan();
  const { mobileView, mobileViewSwitch } = useMobileViewState();

  if (!auth.user) return null;
  return (
    <div className="Header">
      {mobileView && <MenuClosedIconComponent onClick={mobileViewSwitch} />}
      {!mobileView && <MenuIconComponent onClick={mobileViewSwitch} />}
      <section
        className="Header__user"
        style={mobileView ? { width: "auto" } : {}}
      >
        <AvatarComponent src={auth.user.pic} />
        {!mobileView && <span className="name">{auth.user.name}</span>}
      </section>
      <section className="actions">
        {!mobileView && <SearchWord />}
        {canEditGroup(auth.user) && (
          <>
            <TooltippedAddButton
              tooltip="Add a group"
              icon={<AddIconComponent />}
              onClick={() => {
                showEditGroup(GroupModel.createRawNew(), COMMON.CREATE_MODE);
              }}
            />
            <TooltippedAddButton
              tooltip="Add a word"
              icon={<AddIconComponent />}
              onClick={() => {
                let groupId = "";
                if (isItGroupPage(location.pathname)) {
                  groupId = getGroupIdFromURL(location.pathname);
                }
                showEditWord(
                  WordModel.createRawNew({ groupId }),
                  COMMON.CREATE_MODE,
                );
              }}
            />
          </>
        )}
        <PlanIcon user={auth.user} dayState={dayState} />
        <WordsCounterIcon />
        {SecurityService.isAdmin(auth.user) && <DownLoadAllWordsButton />}
        <OffIconComponent
          onClick={() => auth.signout()}
          style={{ marginLeft: "auto" }}
        />
      </section>
    </div>
  );
};

export default Header;
