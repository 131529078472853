import React from "react";
import "./style.scss";
import { GROUP_TYPES } from "../../constants/GROUP_TYPES";

const PlanBlock = ({ plan }) => {
  const planObj = plan.groupsPlan.reduce((accumulator, currentValue) => {
    accumulator[currentValue.groupType] = currentValue.value;
    return accumulator;
  }, {});

  return (
    <span className="PlanBlock">
      <span className="PlanBlock__title">Your Plan</span>
      <span className="PlanBlock__item" key={GROUP_TYPES.SENTENCES}>
        {GROUP_TYPES.SENTENCES}: <b>{planObj[GROUP_TYPES.SENTENCES]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.VERBS}>
        {GROUP_TYPES.VERBS}: <b>{planObj[GROUP_TYPES.VERBS]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.PHRASAL_VERBS}>
        {GROUP_TYPES.PHRASAL_VERBS}: <b>{planObj[GROUP_TYPES.PHRASAL_VERBS]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.EXPRESSIONS}>
        {GROUP_TYPES.EXPRESSIONS}: <b>{planObj[GROUP_TYPES.EXPRESSIONS]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.THEME}>
        {GROUP_TYPES.THEME}: <b>{planObj[GROUP_TYPES.THEME]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.SUPPORT}>
        {GROUP_TYPES.SUPPORT}: <b>{planObj[GROUP_TYPES.SUPPORT]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.ADJECTIVES}>
        {GROUP_TYPES.ADJECTIVES}: <b>{planObj[GROUP_TYPES.ADJECTIVES]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.PREPOSITIONS}>
        {GROUP_TYPES.PREPOSITIONS}: <b>{planObj[GROUP_TYPES.PREPOSITIONS]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.ADDITIONAL}>
        {GROUP_TYPES.ADDITIONAL}: <b>{planObj[GROUP_TYPES.ADDITIONAL]}</b>
      </span>
      <span className="PlanBlock__item" key={GROUP_TYPES.IELTS}>
        {GROUP_TYPES.IELTS}: <b>{planObj[GROUP_TYPES.IELTS]}</b>
      </span>
      <span className="PlanBlock__notes">Notes: {plan.notes}</span>
    </span>
  );
};

export default PlanBlock;
