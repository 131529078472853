import React, { useCallback, useEffect } from "react";
import { GroupModel } from "../models/GroupModel";
import Loader from "../components/library-based-components/Loader";
import { useShowCommonPopup } from "./CommonPopupProvider";
import { LogService } from "../services/LogService";

const GroupsContext = React.createContext(null);

export const GroupsProvider = ({ children }) => {
  const [groups, setGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const showError = useShowCommonPopup();

  const removeGroup = useCallback((group) => {
    setGroups((oldState) => {
      return oldState.filter(({ id }) => group.id !== id);
    });
  }, []);

  const editGroup = useCallback((group) => {
    setGroups((oldState) => {
      return oldState.map((item) => {
        if (item.id === group.id) {
          return group;
        } else {
          return item;
        }
      });
    });
  }, []);

  const getGroupById = useCallback(
    (groupId) => {
      return groups.find(({ id }) => id === groupId);
    },
    [groups],
  );

  const editGroups = useCallback((groups) => {
    setGroups((oldState) => {
      return oldState.map((item) => {
        const newGroup = groups
          .filter((group) => Boolean(group))
          .find(({ id }) => item.id === id);
        if (newGroup) {
          return newGroup;
        } else {
          return item;
        }
      });
    });
  }, []);

  const addGroup = useCallback((group) => {
    setGroups((oldState) => {
      return oldState
        .concat(group)
        .sort((a, b) => a.name.localeCompare(b.name));
    });
  }, []);

  useEffect(() => {
    const getAllGroups = async () => {
      try {
        const groupsData = await GroupModel.getAll();
        setGroups(groupsData);
      } catch (e) {
        LogService.log("Groups get data error", e, showError);
      } finally {
        setLoading(false);
      }
    };
    getAllGroups();
  }, [showError]);

  if (loading) {
    return <Loader />;
  }

  return (
    <GroupsContext.Provider
      value={{
        groups,
        removeGroup,
        editGroup,
        addGroup,
        editGroups,
        getGroupById,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};

export const useGroups = () => {
  const contextValue = React.useContext(GroupsContext);
  if (!contextValue) {
    throw new Error("Tried to use context from outside the provider");
  }
  return contextValue;
};
