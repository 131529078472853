import React, { useCallback } from "react";

const FireworkContext = React.createContext(null);

export const FireworkProvider = ({ children }) => {
  const [init, setInit] = React.useState(false);

  const showFirework = useCallback(() => {
    setInit(true);
    setTimeout(() => setInit(false), 5000);
  }, []);

  return (
    <FireworkContext.Provider value={{ init, showFirework }}>
      {children}
    </FireworkContext.Provider>
  );
};

export const useFireworkState = () => {
  const contextValue = React.useContext(FireworkContext);
  if (!contextValue) {
    throw new Error("Tried to use context from outside the provider");
  }
  return contextValue;
};
