import React, { useCallback } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useGroups } from "../../providers/GroupsProvider";
import { getGroupIdFromURL, isItGroupPage } from "../../helpers/util.helper";

const NavItem = ({ path, icon, label }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { groups } = useGroups();

  const onClick = React.useCallback(() => {
    navigate(path);
  }, [path, navigate]);

  const isSelected = useCallback((pathname, groups, path) => {
    if (pathname === path) {
      return true;
    }

    if (isItGroupPage(pathname)) {
      const groupId = getGroupIdFromURL(pathname);
      const group = groups.find(({ id }) => groupId === id);
      return group ? path.includes(group.type) : false;
    }

    return false;
  }, []);

  return (
    <ListItemButton
      onClick={onClick}
      selected={isSelected(location.pathname, groups, path)}
    >
      <ListItemIcon
        className={isSelected(location.pathname, groups, path) ? "active" : ""}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  );
};

export default NavItem;
