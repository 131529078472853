import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import React from "react";
import IconButton from "@mui/material/IconButton";

const ChildFriendlyIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <ChildFriendlyIcon/>
    </IconButton>
  );
};

export default ChildFriendlyIconComponent;
