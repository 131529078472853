import { EntityModel } from "./EntityModel";
import { FirebaseDBService } from "../services/FirebaseDBService";
import { COLLECTIONS } from "../constants/COLLECTIONS";
import { COMMON } from "../constants/COMMON";
import BrowserDBService from "../services/BrowserDBService";
import { GroupModel } from "./GroupModel";

export class WordModel extends EntityModel {
  static collection = COLLECTIONS.WORDS;

  constructor(word) {
    super(word);
    this.firebaseId = word.firebaseId;
    this.groupId = word.groupId;
    this.word = word.word || "";
    this.translation = word.translation || "";

    this.description = word.description || "";
    this.example = word.example || "";
    this.pic = word.pic || COMMON.NO_PIC;

    this.know = word.know || false;
    this.repeatedAt = word.repeatedAt || Date.now();
    this.repeatCount = word.repeatCount || 0;
  }

  validateCustom(word) {
    if (!word.groupId) {
      throw new Error("no groupId");
    }
    if (!word.firebaseId) {
      throw new Error("no firebaseId");
    }
    if (!word.word) {
      throw new Error("no word");
    }
    if (!word.translation) {
      throw new Error("no translation");
    }
  }

  static async getWordById(id) {
    const localWord = await BrowserDBService.getDocumentById(
      id,
      WordModel.collection,
    );

    if (localWord) {
      return localWord;
    }

    const dbWord = FirebaseDBService.getDocumentById(id, WordModel.collection);
    await BrowserDBService.saveDocumentById(dbWord, WordModel.collection);
    return dbWord;
  }

  static async create(modelData) {
    const duplicate = await FirebaseDBService.getDocumentsWhere(
      WordModel.collection,
      "word",
      modelData.word,
    );
    if (duplicate.length) {
      throw new Error(
        "this word already exists! There could be not enough data in local DB",
      );
    }

    const word = await FirebaseDBService.createDocument(
      modelData,
      WordModel.collection,
      (data) => new WordModel(data),
    );
    await BrowserDBService.createDocument(word, WordModel.collection);
    return word;
  }

  async update() {
    this.validate(this);
    const dbCopy = new WordModel({ ...this, know: false });
    await FirebaseDBService.saveDocumentById(dbCopy, WordModel.collection);
    await BrowserDBService.saveDocumentById(this, WordModel.collection);
  }

  /**
   *
   * @param word
   * @returns {Promise<Group>}
   */
  static async deleteEntity(word) {
    await FirebaseDBService.removeDocument(word.id, WordModel.collection);
    await BrowserDBService.removeDocument(word.id, WordModel.collection);
    return word;
  }

  static async getByGroupId(groupId) {
    const localGroup = await BrowserDBService.getDocumentById(
      groupId,
      GroupModel.collection,
    );

    const fromIndexDB = await BrowserDBService.getAllById(
      groupId,
      WordModel.collection,
      "groupId",
    );

    if (fromIndexDB.length === localGroup.wordCounter) {
      return fromIndexDB;
    }

    const fromFirebase = await FirebaseDBService.getDocumentsWhere(
      WordModel.collection,
      "groupId",
      groupId,
    );
    if (!fromFirebase.length) {
      return [];
    }
    await BrowserDBService.saveAll(fromFirebase, WordModel.collection);
    return fromFirebase;
  }

  static async switchKnow(word) {
    const instance = new WordModel(word);
    instance.know = !instance.know;
    await instance.updateLocalInDB();
    return instance;
  }

  async updateLocalInDB() {
    this.validate(this);
    await BrowserDBService.saveDocumentById(this, WordModel.collection);
  }

  static createRawNew({ groupId, word, translation }) {
    return {
      word: word ?? "",
      translation: translation ?? "",
      pic: COMMON.NO_PIC,
      groupId: groupId ?? "",
    };
  }

  static async downloadGroupWordsToLocalDB(groupId) {
    if (!groupId) {
      throw new Error("no groupId!");
    }
    const words = await FirebaseDBService.getDocumentsWhere(
      WordModel.collection,
      "groupId",
      groupId,
    );
    await BrowserDBService.saveAll(words, WordModel.collection);
    return words;
  }

  static async downloadALLWordsToLocalDB() {
    const words = await FirebaseDBService.getAll(WordModel.collection);
    await BrowserDBService.saveAll(words, WordModel.collection);
  }

  static async searchWordByWordLocalDB(word) {
    return BrowserDBService.searchDocumentsWhere(
      WordModel.collection,
      "word",
      word,
    );
  }

  static async searchWordByTranslationLocalDB(word) {
    return BrowserDBService.searchDocumentsWhere(
      WordModel.collection,
      "translation",
      word,
    );
  }

  static async getGroupUnknownWords(groupId) {
    const words = await WordModel.getByGroupId(groupId);
    return words.filter(({ know }) => !know);
  }

  static async massResetKnow(groupId) {
    const words = await WordModel.getByGroupId(groupId);
    const newWords = words.map((word) => {
      word.know = false;
      return word;
    });
    return BrowserDBService.saveAll(newWords, WordModel.collection);
  }
}
