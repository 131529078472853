import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import React from "react";
import IconButton from "@mui/material/IconButton";

const EmojiPeopleIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <EmojiPeopleIcon/>
    </IconButton>
  );
};

export default EmojiPeopleIconComponent;
