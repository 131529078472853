import AssignmentIcon from "@mui/icons-material/Assignment";
import React from "react";
import { IconButton } from "@mui/material";

const SheetIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <AssignmentIcon />
    </IconButton>
  );
};

export default SheetIconComponent;
