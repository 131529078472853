import React from "react";
import { IconButton } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";

const DownloadIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <GetAppIcon />
    </IconButton>
  );
};

export default DownloadIconComponent;
