import React, { useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { withErrorBoundary } from "../../hocs/withErrorBoundary";
import ButtonComponent from "../library-based-components/ButtonComponent/ButtonComponent";
import SelectComponent from "../library-based-components/SelectComponent";
import TextFieldComponent from "../library-based-components/TextFieldComponent";
import { setFormState } from "../../helpers/form.helper";
import { GROUP_TYPES } from "../../constants/GROUP_TYPES";
import PicChanger from "../PicChanger/PicChanger";
import { LogService } from "../../services/LogService";
import { useShowCommonPopup } from "../../providers/CommonPopupProvider";
import { PicService } from "../../services/PicService";

const GroupEditPopup = ({ setGroup, open, mode, group, onClose, submit }) => {
  const [file, setFile] = useState(null);
  const showError = useShowCommonPopup();

  const getGroupTypes = useCallback(() => {
    return Object.values(GROUP_TYPES).map((type) => {
      return {
        id: type,
        label: type,
      };
    });
  }, []);

  const onSubmit = async (group) => {
    try {
      if (!group.name || !group.type) {
        throw new Error('group does"t have type or name!');
      }
      if (file) {
        group.pic = await PicService.savePic(file);
      }

      submit(group);
      // Really important reset
      // Without this some images could be lost!!
      setFile(null);
    } catch (e) {
      LogService.log("save pic error", e, showError);
    }
  };

  const picOnChange = ({ base64, file }) => {
    setFormState("pic", base64, setGroup);
    setFile(file);
  };

  if (!group) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="alert-dialog-title">{mode} group</DialogTitle>
      <form
        style={{ marginTop: -55 }}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit(group);
        }}
      >
        <DialogContent className="popup-content">
          <PicChanger
            id="GroupEditPopup-PicChanger"
            alt="group-pic"
            pic={group.pic}
            onChange={(value) => picOnChange(value)}
          />
          <div style={{ marginTop: -55 }}>
            <TextFieldComponent
              onChange={(value) => setFormState("name", value, setGroup)}
              value={group.name}
              error={!group.name}
              type="text"
              label="name"
            />
          </div>
          <TextFieldComponent
            onChange={(value) => setFormState("description", value, setGroup)}
            value={group.description}
            type="text"
            label="description"
          />
          <SelectComponent
            label="Group Type"
            style={{ marginTop: 15 }}
            value={group.type}
            error={!group.type}
            isError={() => !group.type}
            values={getGroupTypes()}
            onChange={(value) => setFormState("type", value, setGroup)}
          />
        </DialogContent>
        <DialogActions>
          <ButtonComponent onClick={onClose}>Cancel</ButtonComponent>
          <ButtonComponent type="submit" disabled={!group.name || !group.type}>
            Submit
          </ButtonComponent>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withErrorBoundary(GroupEditPopup);
