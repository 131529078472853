import ExtensionIcon from "@mui/icons-material/Extension";
import React from "react";
import IconButton from "@mui/material/IconButton";

const ExtensionIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <ExtensionIcon/>
    </IconButton>
  );
};

export default ExtensionIconComponent;
