import CloseIcon from "@mui/icons-material/Close";
import React from "react";

import { IconButton } from "@mui/material";

const CloseIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseIconComponent;
