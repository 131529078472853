import React, { useCallback, useState } from "react";
import GroupEditPopup from "../components/popups/GroupEditPopup";
import { useGroups } from "./GroupsProvider";
import { useSnack } from "./SnackProvider";
import { COMMON } from "../constants/COMMON";
import { LogService } from "../services/LogService";
import { useShowCommonPopup } from "./CommonPopupProvider";
import { GroupModel } from "../models/GroupModel";

const GroupEditContext = React.createContext(null);

export const GroupEditPopupProvider = ({ children }) => {
  const showError = useShowCommonPopup();
  const [group, setGroup] = useState(null);
  const [mode, setMode] = useState(null);
  const [open, setOpen] = useState(false);
  const { editGroup, addGroup } = useGroups();
  const showSnack = useSnack();

  const submit = useCallback(
    async (group) => {
      try {
        setOpen(false);
        showSnack("Done!");

        if (mode === COMMON.EDIT_MODE) {
          editGroup(group);
          const newGroup = new GroupModel(group);
          await newGroup.update();
        } else if (mode === COMMON.CREATE_MODE) {
          const newGroup = await GroupModel.create(group);
          addGroup(newGroup);
        }
      } catch (e) {
        LogService.log("error", e, showError);
      }
    },
    [addGroup, editGroup, mode, showSnack, showError],
  );

  const showEditGroup = useCallback((group, mode) => {
    if (!group || !mode) {
      throw new Error("insufficient data");
    }
    setGroup(group);
    setMode(mode);
    setOpen(true);
  }, []);

  return (
    <GroupEditContext.Provider value={showEditGroup}>
      <GroupEditPopup
        open={open}
        mode={mode}
        group={group}
        setGroup={setGroup}
        submit={submit}
        onClose={() => setOpen(false)}
      />
      {children}
    </GroupEditContext.Provider>
  );
};

export const useShowGroupEditPopup = () => {
  const contextValue = React.useContext(GroupEditContext);
  if (!contextValue) {
    throw new Error("Tried to use context from outside the provider");
  }
  return contextValue;
};
