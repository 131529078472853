import React from "react";
import "./style.scss";
import { List } from "@mui/material";
import { useAuth } from "../../../providers/AuthProvider";
import NavItem from "../NavItem";
import { ADMIN_URLS, PUBLIC_URLS, USER_URLS } from "../../../constants/URLS";
import EditIconComponent from "../../library-based-components/icons/EditIconComponent";
import HomeIconComponent from "../../library-based-components/icons/HomeIconComponent";
import AddIconComponent from "../../library-based-components/icons/AddIconComponent";
import QuestionIconComponent from "../../library-based-components/icons/QuestionIconComponent";
import FamilyIconComponent from "../../library-based-components/icons/FamilyIconComponent";
import TreeIconComponent from "../../library-based-components/icons/TreeIconComponent";
import { GROUP_TYPES } from "../../../constants/GROUP_TYPES";
import BuildIconComponent from "../../library-based-components/icons/BuildIconComponent";
import LayersIconComponent from "../../library-based-components/icons/LayersIconComponent";
import AttachmentIconComponent from "../../library-based-components/icons/AttachmentIconComponent";
import EmojiPeopleIconComponent from "../../library-based-components/icons/EmojiPeopleIconComponent";
import ChildFriendlyIconComponent from "../../library-based-components/icons/ChildFriendlyIconComponent";
import ColorPaletteIconComponent from "../../library-based-components/icons/ColorPaletteIconComponent";
import ExtensionIconComponent from "../../library-based-components/icons/ExtensionIconComponent";
import PsychologyIconComponent from "../../library-based-components/icons/PsychologyIconComponent";
import FlyIconComponent from "../../library-based-components/icons/FlyIconComponent";
import securityService from "../../../services/SecurityService";
// import SheetIconComponent from "../../library-based-components/icons/SheetIconComponent";

const Nav = () => {
  const auth = useAuth();

  if (!auth.user) return null;

  return (
    <div className="Nav custom-scroll">
      <List component="nav" aria-label="main nav">
        <NavItem
          label="Home"
          icon={<HomeIconComponent />}
          path={PUBLIC_URLS.HOME}
        />
        <NavItem
          label="Sentences"
          icon={<TreeIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.SENTENCES}`}
        />
        <NavItem
          label="Verbs"
          icon={<BuildIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.VERBS}`}
        />
        <NavItem
          label="Phrasal Verbs"
          icon={<LayersIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.PHRASAL_VERBS}`}
        />
        <NavItem
          label="Expressions"
          icon={<EmojiPeopleIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.EXPRESSIONS}`}
        />
        <NavItem
          label="By Theme"
          icon={<AttachmentIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.THEME}`}
        />
        <NavItem
          label="Support"
          icon={<ChildFriendlyIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.SUPPORT}`}
        />
        <NavItem
          label="Adjectives"
          icon={<ColorPaletteIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.ADJECTIVES}`}
        />
        <NavItem
          label="Prepositions"
          icon={<FlyIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.PREPOSITIONS}`}
        />
        <NavItem
          label="Additional"
          icon={<ExtensionIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.ADDITIONAL}`}
        />
        <NavItem
          label="IELTS"
          icon={<PsychologyIconComponent />}
          path={`${USER_URLS.GROUPS}/${GROUP_TYPES.IELTS}`}
        />
        {securityService.isAdmin(auth.user) && (
          <>
            <NavItem
              label="Users"
              icon={<FamilyIconComponent />}
              path={ADMIN_URLS.USERS}
            />
            <NavItem
              label="Creation"
              icon={<AddIconComponent />}
              path={ADMIN_URLS.CREATION}
            />
            <NavItem
              label="Requests"
              icon={<QuestionIconComponent />}
              path={ADMIN_URLS.INQUIRIES}
            />
          </>
        )}
        <NavItem
          label="Settings"
          icon={<EditIconComponent />}
          path={USER_URLS.SETTINGS}
        />
        {/*<NavItem*/}
        {/*  label="Statistic"*/}
        {/*  icon={<SheetIconComponent />}*/}
        {/*  path={USER_URLS.PLANS}*/}
        {/*/>*/}
      </List>
    </div>
  );
};

export default Nav;
