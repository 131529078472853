import React from "react";
import "./style.scss";
import EditIconComponent from "../../library-based-components/icons/EditIconComponent";
import DeleteIconComponent from "../../library-based-components/icons/DeleteIconComponent";
import SpeakIconComponent from "../../SpeakIconComponent";
import { useGroups } from "../../../providers/GroupsProvider";
import { canEditWord } from "../../../helpers/security.helper";
import { COMMON } from "../../../constants/COMMON";
import { useShowConfirm } from "../../../providers/ConfirmPopupProvider";
import { useAuth } from "../../../providers/AuthProvider";
import { USER_URLS } from "../../../constants/URLS";
import { useSnack } from "../../../providers/SnackProvider";
import { useNavigate } from "react-router-dom";
import useSubmit from "../../../hooks/useSubmit";
import { WordModel } from "../../../models/WordModel";
import { GroupModel } from "../../../models/GroupModel";
import { useShowWordEditPopup } from "../../../providers/WordEditPopupProvider";
import { useWords } from "../../../providers/WordsProvider";

const WordSearchRow = ({ word, reset }) => {
  const auth = useAuth();
  const showConfirm = useShowConfirm();
  const { getGroupById, editGroup } = useGroups();
  const showSnack = useSnack();
  const navigate = useNavigate();
  const { showEditWord } = useShowWordEditPopup();
  const { removeWord: removeWordFromList } = useWords();

  const group = getGroupById(word.groupId);

  const wordOnClick = (wordId) => {
    navigate(`${USER_URLS.WORD}/${wordId}`);
    reset();
  };

  const groupOnClick = async (groupId) => {
    navigate(`${USER_URLS.GROUP}/${groupId}`);
    reset();
  };

  const { submit: removeWordCb } = useSubmit({
    sendRequest: async (word) => {
      await WordModel.deleteEntity(word);
      removeWordFromList(word);
      const newGroup = await GroupModel.wordOnDelete(word);
      editGroup(newGroup);
      showSnack("Success");
    },
  });

  return (
    <div className="WordSearchRow">
      <SpeakIconComponent message={word.word} className="volumeIcon" />
      <div className="word">
        <div className="text" onClick={() => wordOnClick(word.id)}>
          {word.word}
        </div>
        <div className="translation">{word.translation}</div>
        <span className="text" onClick={() => groupOnClick(word.groupId)}>
          ({group.name})
        </span>
      </div>
      <img className="word-pic" src={word.pic} alt={word.word} />
      {canEditWord(auth.user) && (
        <>
          <DeleteIconComponent
            onClick={() => showConfirm(() => removeWordCb(word))}
          />
          <EditIconComponent
            onClick={() =>
              showEditWord(word, COMMON.EDIT_MODE, () => showSnack("Done"))
            }
          />
        </>
      )}
    </div>
  );
};

export default WordSearchRow;
