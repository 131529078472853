import React, { useCallback, useRef, useState } from "react";
import { COMMON } from "../constants/COMMON";
import { LogService } from "../services/LogService";
import { useShowCommonPopup } from "./CommonPopupProvider";
import { GroupModel } from "../models/GroupModel";
import WordEditPopup from "../components/popups/WordEditPopup";
import { WordModel } from "../models/WordModel";
import { SpeakService } from "../services/SpeakService";
import { useGroups } from "./GroupsProvider";
import { useWords } from "./WordsProvider";

const WordEditContext = React.createContext(null);

export const WordEditPopupProvider = ({ children }) => {
  const cbRef = useRef();
  const showError = useShowCommonPopup();
  const [word, setWord] = useState(null);
  const [mode, setMode] = useState(null);
  const [open, setOpen] = useState(false);
  const { editGroups, editGroup } = useGroups();
  const { fetchWords } = useWords();
  const [progress, setProgress] = useState(false);

  const submit = useCallback(async () => {
    try {
      setProgress(true);

      const cb = cbRef.current;
      if (mode === COMMON.EDIT_MODE) {
        const originalWordFromDB = await WordModel.getById(word.id);
        const { oldGroup, newGroup } = await GroupModel.wordOnEdit(
          word,
          originalWordFromDB,
        );

        // This should be AFTER group change because otherwise we will not get the original word
        const newWord = new WordModel(word);
        await newWord.update();

        editGroups([newGroup, oldGroup]);
        if (cb) {
          cb({ newWord, newGroup, oldGroup });
        }
      } else if (mode === COMMON.CREATE_MODE) {
        const newWord = await WordModel.create(word);
        const newGroup = await GroupModel.wordOnAdd(word);
        editGroup(newGroup);
        if (cb) {
          cb({ newWord, newGroup });
        }
      }
      // This is where you have to comment if you have a lot of words to change
      // fetchWords();

      setOpen(false);
    } catch (e) {
      LogService.log("error", e, showError);
    } finally {
      setProgress(false);
    }
  }, [editGroup, fetchWords, editGroups, showError, mode, word]);

  const showEditWord = useCallback((word, mode, cb) => {
    if (!word || !mode) {
      throw new Error("insufficient data");
    }

    setWord(word);
    setMode(mode);
    SpeakService.speak(word.word);
    setOpen(true);
    cbRef.current = cb;
  }, []);

  return (
    <WordEditContext.Provider value={{ showEditWord, open }}>
      <WordEditPopup
        open={open}
        mode={mode}
        word={word}
        progress={progress}
        setWord={setWord}
        submit={submit}
        onClose={() => {
          if (word.word.id) {
            SpeakService.speak(word.word);
          }
          setOpen(false);
        }}
      />
      {children}
    </WordEditContext.Provider>
  );
};

export const useShowWordEditPopup = () => {
  const contextValue = React.useContext(WordEditContext);
  if (!contextValue) {
    throw new Error("Tried to use context from outside the provider");
  }
  return contextValue;
};
