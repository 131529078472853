const namePattern = /^[a-z0-9 ,.'-]+$/i;
const emailPattern = /^.+@.+$/;
const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
const milestoneNumber = 4;

export const validEmail = (email) => {
  if (email) return emailPattern.test(email);
  return false;
};

export const validPassword = (password) => {
  if (password) return passwordPattern.test(password);
  return false;
};

export const validName = (name) => {
  if (name) return namePattern.test(name);
  return false;
};

export const trimValue = (name) => {
  if (!name) return name;
  return name.replace(/\s{2,}/g, " ").trimStart();
};

export const canStartTraining = (wordsLength) => {
  if (!wordsLength) return false;
  if (wordsLength <= milestoneNumber) return false;
  return true;
};

export const canStartSentencesTraining = (words) => {
  if (!words) return false;
  if (!Array.isArray(words)) return false;
  const sentensesLength = getSentencesLength(words);
  if (sentensesLength <= milestoneNumber) return false;
  return true;
};

export const isItSentence = (text) => {
  const splitted = text.split(/\s+/);
  return splitted.length >= 3 && !~text.indexOf(";");
};

export const getSentencesLength = (words) => {
  let counter = 0;
  words.forEach(({ word }) => {
    if (isItSentence(word)) {
      counter++;
    }
  });

  return counter;
};

export const canResetKnowStatus = (group) => {
  if (!group) return false;
  if (group.knownWords === 0) return false;
  return true;
};
