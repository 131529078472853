import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import React from "react";
import { IconButton } from "@mui/material";

const FamilyIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <FamilyRestroomIcon />
    </IconButton>
  );
};

export default FamilyIconComponent;
