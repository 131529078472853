export const USER_URLS = {
  SETTINGS: "/settings",
  GROUPS: "/groups",
  GROUP: "/group",
  WORD: "/word",
  PLANS: "/plans",
};

export const PUBLIC_URLS = {
  LOGIN: "/login",
  HOME: "/",
  SEND_REQUEST: "/sendRequest",
  ACCEPT_INVITE: "/acceptInvite",
  RESET_PASS: "/resetPass",
};

export const ADMIN_URLS = {
  USERS: "/users",
  INQUIRIES: "/inquiries",
  CREATION: "/creation",
};
