import React, { useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { withErrorBoundary } from "../../hocs/withErrorBoundary";
import ButtonComponent from "../library-based-components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../library-based-components/TextFieldComponent";
import { setFormState } from "../../helpers/form.helper";
import PicChanger from "../PicChanger/PicChanger";
import { LogService } from "../../services/LogService";
import { useShowCommonPopup } from "../../providers/CommonPopupProvider";
import { PicService } from "../../services/PicService";
import SpeakIconComponent from "../SpeakIconComponent";
import WordStatusIcon from "../WordStatusIcon";
import AutocompleteComponent from "../library-based-components/AutocompleteComponent";
import { transformForAutoComplete } from "../../helpers/util.helper";
import { useGroups } from "../../providers/GroupsProvider";

const WordEditPopup = ({
  setWord,
  open,
  mode,
  word,
  onClose,
  submit,
  progress,
}) => {
  const [file, setFile] = useState(null);
  const [process, setProcess] = useState(false);
  const showError = useShowCommonPopup();
  const { groups } = useGroups();

  const onSubmit = async (word) => {
    try {
      setProcess(true);
      if (file) {
        word.pic = await PicService.savePic(file);
      }
      submit(word);
      // Really important reset
      // Without this some images could be lost!!
      setFile(null);
    } catch (e) {
      LogService.log("save pic error", e, showError);
    } finally {
      setProcess(false);
    }
  };

  const transform = useCallback((groups) => {
    return transformForAutoComplete(groups, "name");
  }, []);

  const picOnChange = ({ base64, file }) => {
    setFormState("pic", base64, setWord);
    setFile(file);
  };

  if (!word) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="dialog-title">
        <span>{mode} word</span>
        {word && <SpeakIconComponent message={word.word || ""} />}
        {word && <WordStatusIcon word={word} />}
      </DialogTitle>
      <DialogContent className="popup-content">
        <PicChanger
          id="WordEditPopup-PicChanger"
          alt="word-pic"
          pic={word.pic}
          onChange={(value) => picOnChange(value)}
        />
        <div style={{ marginTop: -45 }}>
          <TextFieldComponent
            multiline
            onChange={(value) => setFormState("word", value, setWord)}
            value={word.word}
            error={!word.word}
            type="text"
            label="word"
          />
        </div>
        <TextFieldComponent
          multiline
          onChange={(value) => setFormState("translation", value, setWord)}
          value={word.translation}
          error={!word.translation}
          type="text"
          label="translation"
        />
        <AutocompleteComponent
          options={transform(groups)}
          value={word.groupId}
          label="Search group"
          style={{ marginTop: 20 }}
          onChange={(value) => setFormState("groupId", value, setWord)}
        />
        <TextFieldComponent
          multiline
          onChange={(value) => setFormState("example", value, setWord)}
          value={word.example}
          type="text"
          label="example"
        />
        <TextFieldComponent
          multiline
          onChange={(value) => setFormState("description", value, setWord)}
          value={word.description}
          type="text"
          label="description"
        />
      </DialogContent>
      <DialogActions>
        <ButtonComponent onClick={onClose}>Cancel</ButtonComponent>
        <ButtonComponent
          useLoading={true}
          disabled={
            !word.word ||
            !word.groupId ||
            !word.translation ||
            process ||
            progress
          }
          loading={process || progress}
          onClick={() => onSubmit(word)}
        >
          Submit
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default withErrorBoundary(WordEditPopup);
