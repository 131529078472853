import React from "react";
import { IconButton } from "@mui/material";
import AccountTree from "@mui/icons-material/AccountTree";

const TreeIconComponent = (props) => {
  return (
    <IconButton {...props}>
      <AccountTree />
    </IconButton>
  );
};

export default TreeIconComponent;
