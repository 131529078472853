import React, { useCallback, useState } from "react";
import { LogService } from "../services/LogService";
import { useShowCommonPopup } from "../providers/CommonPopupProvider";
import { WordModel } from "../models/WordModel";
import LoopIconComponent from "./library-based-components/icons/LoopIconComponent";
import { useSnack } from "../providers/SnackProvider";
import DownloadIconComponent from "./library-based-components/icons/DownloadIconComponent";
import { GroupModel } from "../models/GroupModel";

const DownLoadAllWordsButton = () => {
  const showError = useShowCommonPopup();
  const [progress, setProgress] = useState(false);
  const showSnack = useSnack();

  const onClick = useCallback(async () => {
    try {
      setProgress(true);
      await Promise.all([
        WordModel.downloadALLWordsToLocalDB(),
        GroupModel.downloadALLGroupsToLocalDB(),
      ]);
      showSnack("Done!");
    } catch (e) {
      LogService.log("download all words error", e, showError);
    } finally {
      setProgress(false);
    }
  }, [showError, showSnack]);

  if (progress) {
    return <LoopIconComponent className="rotating" />;
  }

  return <DownloadIconComponent onClick={onClick} />;
};

export default DownLoadAllWordsButton;
