import LayersIcon from "@mui/icons-material/Layers";
import React from "react";
import IconButton from "@mui/material/IconButton";

const LayersIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <LayersIcon/>
    </IconButton>
  );
};

export default LayersIconComponent;
