import BuildIcon from "@mui/icons-material/Build";
import React from "react";
import IconButton from "@mui/material/IconButton";

const BuildIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <BuildIcon/>
    </IconButton>
  );
};

export default BuildIconComponent;
