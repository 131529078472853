import React, { useCallback, useEffect } from "react";
import cloneDeep from "clone-deep";
import { LogService } from "../services/LogService";
import { useShowCommonPopup } from "./CommonPopupProvider";
import Loader from "../components/library-based-components/Loader";
import { useLocation, useParams } from "react-router-dom";
import { WordModel } from "../models/WordModel";
import { isItGroupPage } from "../helpers/util.helper";

const WordsContext = React.createContext(null);

export const WordsProvider = ({ children }) => {
  const showError = useShowCommonPopup();
  const location = useLocation();
  const params = useParams();
  const [words, setWords] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const removeWord = useCallback((word) => {
    setWords((oldState) => {
      return oldState.filter(({ id }) => word.id !== id);
    });
  }, []);

  const editWord = useCallback((word) => {
    setWords((oldState) => {
      return oldState.map((item) => {
        if (item.id === word.id) {
          return word;
        } else {
          return item;
        }
      });
    });
  }, []);

  const addWord = useCallback((word) => {
    setWords((oldState) => {
      const newState = cloneDeep(oldState);
      newState.push(word);
      return newState;
    });
  }, []);

  const fetchWords = useCallback(async () => {
    try {
      if (isItGroupPage(location.pathname)) {
        setWords([]);
        setLoading(true);
        const wordsData = await WordModel.getByGroupId(params.id);
        setWords(wordsData.sort((a, b) => b.createdAt - a.createdAt));
      }
    } catch (e) {
      LogService.log("words get data error", e, showError);
    } finally {
      setLoading(false);
    }
  }, [location.pathname, params.id, showError]);

  useEffect(() => {
    fetchWords();
  }, [fetchWords]);

  return (
    <WordsContext.Provider
      value={{
        words,
        addWord,
        editWord,
        removeWord,
        fetchWords,
      }}
    >
      {loading && (
        <div style={{ position: "absolute", right: "50%", top: 20 }}>
          <Loader />
        </div>
      )}
      {children}
    </WordsContext.Provider>
  );
};

export const useWords = () => {
  const contextValue = React.useContext(WordsContext);

  if (!contextValue) {
    throw new Error("Tried to use context from outside the provider");
  }

  return contextValue;
};
