import React from "react";
import "./style.scss";
import { GROUP_TYPES } from "../../constants/GROUP_TYPES";

const StatisticBlock = ({ dayState }) => {
  const calculateState = (dayState) => {
    const res = {};
    const doneObj = {};
    dayState.plan.forEach(({ groupType, value }) => {
      doneObj[groupType] = true;

      const dayData = dayState.done.find(
        (groupData) => groupType === groupData.groupType,
      );
      const resObj = { plan: value, value: null };
      // No need to learn
      if (+value === 0) {
        resObj.value = `: ${dayData.value} 😏`;
        res[groupType] = resObj;
        return;
      }

      // Didn't learn today
      if (+dayData.value === 0) {
        resObj.value = `: -${value} 🤬`;
        res[groupType] = resObj;
        doneObj[groupType] = false;
        return;
      }

      const difference = dayData.value - value;
      // Learned more than needed
      if (difference >= 0) {
        doneObj[groupType] = true;
        resObj.value = `: ${difference > 0 ? "+" : ""}${difference} 🤩`;
        res[groupType] = resObj;
        return;
      }

      // User is in process
      doneObj[groupType] = false;

      // Half of learning status
      const halfOfPlan = value / 2;
      if (dayData.value >= halfOfPlan) {
        resObj.value = `: ${difference} 😠`;
      } else {
        resObj.value = `: ${difference} 😡`;
      }

      res[groupType] = resObj;
    });

    const done = Object.values(doneObj).filter((value) => value === true);
    return [res, done.length === dayState.plan.length];
  };

  if (!dayState) {
    return <div>No Plan</div>;
  }

  const [currentState, done] = calculateState(dayState);
  return (
    <span className={`StatisticBlock ${done ? "done-block" : ""}`}>
      <b>Date: {dayState.id}</b>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.SENTENCES}: {currentState[GROUP_TYPES.SENTENCES].plan}{" "}
        <b>{currentState[GROUP_TYPES.SENTENCES].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.VERBS}: {currentState[GROUP_TYPES.VERBS].plan}{" "}
        <b>{currentState[GROUP_TYPES.VERBS].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.PHRASAL_VERBS}:{" "}
        {currentState[GROUP_TYPES.PHRASAL_VERBS].plan}{" "}
        <b>{currentState[GROUP_TYPES.PHRASAL_VERBS].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.EXPRESSIONS}: {currentState[GROUP_TYPES.EXPRESSIONS].plan}{" "}
        <b>{currentState[GROUP_TYPES.EXPRESSIONS].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.THEME}: {currentState[GROUP_TYPES.THEME].plan}{" "}
        <b>{currentState[GROUP_TYPES.THEME].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.SUPPORT}: {currentState[GROUP_TYPES.SUPPORT].plan}{" "}
        <b>{currentState[GROUP_TYPES.SUPPORT].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.ADJECTIVES}: {currentState[GROUP_TYPES.ADJECTIVES].plan}{" "}
        <b>{currentState[GROUP_TYPES.ADJECTIVES].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.PREPOSITIONS}:{" "}
        {currentState[GROUP_TYPES.PREPOSITIONS].plan}{" "}
        <b>{currentState[GROUP_TYPES.PREPOSITIONS].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.ADDITIONAL}: {currentState[GROUP_TYPES.ADDITIONAL].plan}{" "}
        <b>{currentState[GROUP_TYPES.ADDITIONAL].value}</b>
      </span>
      <span className="StatisticBlock__group-data">
        {GROUP_TYPES.IELTS}: {currentState[GROUP_TYPES.IELTS].plan}{" "}
        <b>{currentState[GROUP_TYPES.IELTS].value}</b>
      </span>
      <span className="StatisticBlock__notes">Notes: {dayState.notes}</span>
    </span>
  );
};

export default StatisticBlock;
