import React from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import AddIconComponent from "../../library-based-components/icons/AddIconComponent";
import { WordModel } from "../../../models/WordModel";
import { COMMON } from "../../../constants/COMMON";
import TooltippedAddButton from "../../library-based-components/TooltippedAddButton";
import { useShowWordEditPopup } from "../../../providers/WordEditPopupProvider";
import { TranslationService } from "../../../services/TranslationService";
import { getGroupIdFromURL, isItGroupPage } from "../../../helpers/util.helper";

const WordSearchCreateWord = ({ word }) => {
  const location = useLocation();
  const { showEditWord } = useShowWordEditPopup();

  const getRawWord = (value, groupId) => {
    const english = TranslationService.isItEnglish(value);
    return WordModel.createRawNew({
      word: english ? value : "",
      translation: english ? "" : value,
      groupId,
    });
  };

  return (
    <div className="WordSearchCreateWord">
      Create a new word: <b>{word} </b>
      <TooltippedAddButton
        tooltip="Add a word"
        icon={<AddIconComponent />}
        onClick={() => {
          let groupId = "";
          if (isItGroupPage(location.pathname)) {
            groupId = getGroupIdFromURL(location.pathname);
          }

          showEditWord(getRawWord(word, groupId), COMMON.CREATE_MODE);
        }}
      />
    </div>
  );
};

export default WordSearchCreateWord;
