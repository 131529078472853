import cloneDeep from "clone-deep";
import { PUBLIC_URLS, USER_URLS } from "../constants/URLS";
import { LogService } from "../services/LogService";
import { BrowserStorageService } from "../services/BrowserStorageService";
import { GROUP_TYPES } from "../constants/GROUP_TYPES";

export const makeId = (length = 27) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getNiceDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
};

export const clone = cloneDeep;

export const isUserURL = (pathname) => {
  return Object.values(USER_URLS).find((key) => key === pathname);
};

export const getInviteUrl = (inquiry) => {
  return `${PUBLIC_URLS.ACCEPT_INVITE}/${inquiry.id}`;
};

export const transformForAutoComplete = (data, prop) => {
  return data.map((item) => ({ id: item.id, label: item[prop] }));
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isItGroupPage = (pathname) => {
  return pathname.includes(`${USER_URLS.GROUP}/`);
};

export const getGroupIdFromURL = (pathname) => {
  return pathname.replace(`${USER_URLS.GROUP}/`, "");
};

export const getMixedArray = (words) => {
  return [...words].sort(() => Math.random() - 0.5);
};

export const getWordsLength = (words) => {
  if (words.length < 10) {
    return words.length;
  } else {
    return 10;
  }
};

export const isEscKey = (event) => {
  return event.key === "Escape";
};

export const isSpaceKey = (event) => {
  return event.key === " ";
};

export const isEnterKey = (event) => {
  return event.key === "Enter";
};

const redirectToNextGroup = (pathname, navigate) => {
  switch (pathname) {
    case `${USER_URLS.GROUPS}/${GROUP_TYPES.SENTENCES}`:
      navigate(`${USER_URLS.GROUPS}/${GROUP_TYPES.VERBS}`);
      break;
    case `${USER_URLS.GROUPS}/${GROUP_TYPES.VERBS}`:
      navigate(`${USER_URLS.GROUPS}/${GROUP_TYPES.PHRASAL_VERBS}`);
      break;
    case `${USER_URLS.GROUPS}/${GROUP_TYPES.PHRASAL_VERBS}`:
      navigate(`${USER_URLS.GROUPS}/${GROUP_TYPES.EXPRESSIONS}`);
      break;
    case `${USER_URLS.GROUPS}/${GROUP_TYPES.EXPRESSIONS}`:
      navigate(`${USER_URLS.GROUPS}/${GROUP_TYPES.THEME}`);
      break;
    case `${USER_URLS.GROUPS}/${GROUP_TYPES.THEME}`:
      navigate(`${USER_URLS.GROUPS}/${GROUP_TYPES.SUPPORT}`);
      break;
    case `${USER_URLS.GROUPS}/${GROUP_TYPES.SUPPORT}`:
      navigate(`${USER_URLS.GROUPS}/${GROUP_TYPES.ADJECTIVES}`);
      break;
    case `${USER_URLS.GROUPS}/${GROUP_TYPES.ADJECTIVES}`:
      navigate(`${USER_URLS.GROUPS}/${GROUP_TYPES.PREPOSITIONS}`);
      break;
    default:
      console.log("Sorry");
  }
};

const isAllPlanDone = (dayState, groupsPlan) => {
  let doneCounter = 0;
  for (const planData of groupsPlan) {
    // No need to learn
    if (!Number(planData.value)) {
      doneCounter += 1;
    }

    const today = dayState.done.find(
      ({ groupType }) => planData.groupType === groupType,
    );

    if (!Number(today.value)) {
      continue;
    }

    if (Number(today.value) >= Number(planData.value)) {
      doneCounter += 1;
    }
  }

  return doneCounter === groupsPlan.length;
};
export const showCongrats = ({
  dayState,
  showFirework,
  user,
  groupType,
  showSnack,
  showParticles,
  navigate,
  pathname,
}) => {
  try {
    let shownDays = BrowserStorageService.getData("firework-shown");
    const isShown = shownDays?.includes(dayState.id);

    // We are done
    if (isShown) {
      return;
    }
    if (isAllPlanDone(dayState, user.plan.groupsPlan) && !isShown) {
      shownDays = [dayState.id];
      BrowserStorageService.setData("firework-shown", shownDays);
      setTimeout(() => {
        showFirework();
      }, 2000);
      return;
    }

    const groupStatistic = dayState.done.find(
      (group) => groupType === group.groupType,
    );
    const groupPlan = user.plan.groupsPlan.find(
      (group) => groupType === group.groupType,
    );

    if (!+groupStatistic.value || !+groupPlan.value) {
      return;
    }

    if (+groupStatistic.value === +groupPlan.value) {
      showParticles();
      setTimeout(() => {
        redirectToNextGroup(pathname, navigate);
      }, 2000);
      showSnack(
        <span>
          You finished plan for {groupType}!{" "}
          <span role="img" aria-label="kiss">
            😘
          </span>
        </span>,
      );
    }
  } catch (e) {
    LogService.log("showCongrats error", e);
  }
};

export const mutateStateOnEdit = (newState, newWord) => {
  newState.current = newWord;
  newState.unknown = newState.unknown.map((word) => {
    if (word.id === newWord.id) {
      return newWord;
    } else {
      return word;
    }
  });
  newState.words = newState.words.map((word) => {
    if (word.id === newWord.id) {
      return newWord;
    } else {
      return word;
    }
  });
};
