import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import React from "react";
import IconButton from "@mui/material/IconButton";

const FlyIconComponent = (props) => {
  return (
    <IconButton {...props} size="large">
      <EmojiNatureIcon/>
    </IconButton>
  );
};

export default FlyIconComponent;
